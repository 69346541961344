

[class*="pill-"] {
    display: inline-flex;
    padding: 5px 11px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.1em;
    font-weight: 700;
    border-radius: var(--default-border-radius);
}

.pill-primary {
    background-color: var(--default-primary);
    color: var(--default-white);
}

.pill-secondary {
    background-color: var(--default-secondary);
    color: var(--default-white);
}

.pill-grey {
    background-color: var(--default-grey);
    color: var(--default-white);
}
