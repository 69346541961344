
[class*="notice-"] {
    display: inline-flex;
    padding: 1.5em 1em;
    width: 100%;
    border-radius: var(--defualt-border-radius);
    font-weight: 500;
    width: 100%;
}

.notice-secondary {
    background-color: var(--default-secondary);
    color: var(--default-white);
}

.notice-light {
    background-color: var(--default-light);
    color: var(--default-contrast);
}

