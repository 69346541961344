main .multiselect {
    border-radius: 0;
    border: none;
    background-color: var(--default-light);
}

main .multiselect-tags .multiselect-tag {
    background: none;
    color: var(--default-contrast);
    font-size: var(--font-size-small);
    font-weight: var(--font-size-base);
}

main .multiselect-tags .multiselect-tags-search[type="text"] {
    height: auto;
    padding: 0;
    background: none;
}

main .multiselect-placeholder {
    font-size: var(--font-size-small);
    font-weight: var(--font-size-base);
}
