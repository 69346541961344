.fields-container {
    display: flex;
}

.fields-container + .fields-container {
    margin-top: 2em;
}

.fields-container .field {
    flex: 0 0 50%;
}

.fields-container .field + .field {
    margin-top: 0;
}

.fields-container .field-short {
    width: 90%;
}
