
:root {
    --default-border-radius: 4px;
    --nav-width: 80px;
    --sidebar-width: 300px;
    --main-padding: 50px;

    --font-size-small: 14px;
    --font-size-base: 16px;

    --font-weight-light: 300;
    --font-weight-base: 400;

    --transition-delay: 300ms;
    --transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
}
