
[class*="button-"] {
    display: inline-flex;
    padding: 1.5em 3em;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1.2px;
    border: none;
    cursor: pointer;
    border: 1px solid transparent;
    transition: color var(--transition-delay) var(--transition-timing-function),
                background var(--transition-delay) var(--transition-timing-function),
                border var(--transition-delay) var(--transition-timing-function);
}

.button-primary {
    background-color: var(--default-primary);
    color: var(--default-white);
}

.button-primary:hover {
    background-color: var(--default-white);
    border: 1px solid var(--default-primary);
    color: var(--default-primary);
}

.button-primary-outline {
    background-color: transparent;
    border: 1px solid var(--default-primary);
    color: var(--default-primary);
}

.button-primary-outline:hover {
    border: 1px solid transparent;
    background-color: var(--default-primary);
    color: var(--default-white);
}

.button-secondary {
    background-color: var(--default-secondary);
    color: var(--default-white);
}

.button-secondary:hover {
    background-color: var(--default-white);
    border: 1px solid var(--default-secondary);
    color: var(--default-secondary);
}

.button-secondary-outline {
    background-color: transparent;
    border: 1px solid var(--default-secondary);
    color: var(--default-secondary);
}

.button-secondary-outline:hover {
    border: 1px solid transparent;
    background-color: var(--default-secondary);
    color: var(--default-white);
}

.button-section {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--default-light);
}

.button-section svg {
    width: 24px;
    margin-bottom: 1em;
}

.button-icon {
    padding: 1em 2em;
    align-items: center;
    background: none;
    color: var(--default-contrast);
}

.button-icon:hover {
    border: 1px solid transparent;
    color: var(--default-primary);
}

.button-icon svg {
    width: 1.5em;
}

.button-disabled {
    pointer-events: none;
    background-color: rgba(var(--default-contrast-rgb), 0.25);
    color: var(--default-white);
}
