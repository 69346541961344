
.field-inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 14px 5px 0;
    border-radius: var(--default-border-radius);
    font-size: 14px;
}

.field-inline .error {
    color: var(--default-error);
    margin: 10px 0;
    font-size: 14px;
}

.field-inline + .field-inline {
    margin-top: 4px;
}

.field-inline > label {
    flex: 0 0 20%;
    padding-right: 20px;
}

.field-inline input {
    flex: 0 0 80%;
    border: none;
    padding: 12px 14px 11px;
    text-align: left;
    line-height: 17px;
    font-size: var(--font-size-small);
    color: var(--default-contrast);
    background-color: var(--default-light);
}

.field-inline .multiselect {
    flex: 0 0 80%;
}

.field-checkboxes div {
    margin-right: 15px;
}

.field-checkboxes div label {
    line-height: 40px;
    cursor: pointer;
}

.field-checkboxes div input {
    margin-top: 0;
}
