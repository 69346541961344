
.weight-medium {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.text-error {
    color: var(--default-error);
}
