:root {
    --default-primary: #E12A3B;
    --default-secondary: #1F71B9;
    --default-contrast: #263947;
    --default-light: #F2F2F2;
    --default-white: #FFFFFF;
    --default-off-white: #f9f9f9;
    --default-border-white: #D3D7DA;
    --default-grey: #949ea5;
    --default-grey-light: #B3B9BE;

    --default-light-rgb: 248, 248, 248;
    --default-contrast-rgb: 38, 57, 71;

    --default-info: #1F71B9;
    --default-success: #24B91F;
    --default-warning: #E3731B;
    --default-error: #B91F1F;
    --default-contrast-error: #f96b6b;

    --default-dash-red: #B91F1F;
    --default-dash-yellow: #fae75b;
    --default-dash-pink: #ff99ff;
    --default-dash-green: #24B91F;
    --default-dash-purple: #9C20B9;
    --default-dash-blue: #1F71B9;
    --default-dash-orange: #E3731B;

    --default-nav-background: var(--default-contrast);
    --default-nav-text: var(--default-white);
    --default-sidebar-text: var(--default-contrast);
    --default-sidebar-background: var(--default-light);

    --default-text-color: var(--default-contrast);
    --default-input-background: var(--default-light);
}
