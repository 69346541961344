
.content-block {
    color: var(--default-contrast);
}

.content-block + .content-block {
    margin-top: 24px;
}

.content-block h3 {
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 8px;
    margin-top: 0;
}

.content-block p {
    font-size: 14px;
}

.content-block-section {
    border-radius: var(--default-border-radius);
    padding: 12px 14px;
    background-color: var(--default-light);
}

.content-block-section + .content-block-section,
.content-block-section + .content-block-split-section,
.content-block-split-section + .content-block-split-section {
    margin-top: 4px;
}

.content-block-split-section {
    display: flex;
}

.content-block-split-section
.content-block-section {
    flex: 0 0 calc(50% - 2px);
}

.content-block-split-section
.content-block-section:nth-child(2n-1) {
    margin-right: 2px;
}

.content-block-split-section
.content-block-section:nth-child(2n) {
    margin-left: 2px;
}

.content-block-split-section
.content-block-section +
.content-block-section {
    margin-top: 0;
}

.content-space-between {
    display: flex;
    justify-content: space-between;
}
