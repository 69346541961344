
* {
    box-sizing: border-box;
}

textarea {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Nunito Sans', sans-serif;
}

input, textarea, select {
    font-family: 'Nunito Sans', sans-serif;
    line-height: 1.5;
}

h1 {
    padding: 0;
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    margin: 0;
}

.nprogress-busy a,
.nprogress-busy button {
    transition: opacity 100ms ease-in;
    opacity: .5;
}

