

textarea {
    display: block;
    width: 100%;
    border: none;
    background-color: var(--default-light);
    border-radius: var(--default-border-radius);
    padding: 1.5em 1em;
}
